
// import { useMemo } from 'react';

// material-ui
import { Box, useMediaQuery } from '@mui/material';
import ThemeSwitch from './ThemeSwitch';
// project import


// import Message from './Message';
import Profile from './Profile';
// import Notification from './Notification';
import Localization from './Localization';
import MobileSection from './MobileSection';
import { useMemo } from 'react';
import useConfig from 'hooks/useConfig';
import Search from './Search';
// import MegaMenuSection from './MegaMenuSection';


// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { i18n } = useConfig();
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const megaMenu = useMemo(() => <MegaMenuSection />, []);
  const localization = useMemo(() => <Localization />, [i18n]);

  return (
    <>
      {/* {!matchesXs && megaMenu} */}
      
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
      {<Search />}

      {/* <Notification /> */}
      {/* <Message /> */}
      {localization}
      <ThemeSwitch />
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;