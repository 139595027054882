import React, { useState, useEffect } from 'react';
import { Box, Typography, OutlinedInput, TextField, Autocomplete } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import hilconaData from '../mapping/hilcona.json';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSearch } from '../hooks/useSearchContext';
import { validateTextInput } from 'utils/commonFunctions';
import { Select, MenuItem, IconButton, InputAdornment } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const BasicDetails = () => {
  const {
    startDate, setStartDate,
    endDate, setEndDate,
    invoiceNumber, setInvoiceNumber,
    countryOfOrigin, setCountryOfOrigin,
    documentNumber, setDocumentNumber,
    documentType, setDocumentType,
    documentCategory, setDocumentCategory,
    profile, setProfile,
    company, setCompany,
    dynamicField
  } = useSearch();

  const documentCategories = dynamicField ? Object.keys(dynamicField.main)
    .filter(key => key !== 'base' && key !== 'general')
    .map(type => ({
      label: type,
      value: `${type.toLowerCase()}`
    })): [];

  const handleDocCatChange = (event, newValue) => {
    setDocumentCategory(newValue?.value || '');
  };

  const intl = useIntl();

  return (
    <Box sx={{ mt: 0, display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
      <Typography>
        <FormattedMessage id="basicDetails" />
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <DatePicker
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => <TextField {...params} sx={{ width: '50%' }} />}
              inputFormat={intl.formatMessage({ id: "dateFormatType" })}
              label={intl.formatMessage({ id: "startDate" })}
              maxDate={new Date()}
            />
            <DatePicker
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => <TextField {...params} sx={{ width: '50%' }} />}
              label={intl.formatMessage({ id: "endDate" })}
              inputFormat={intl.formatMessage({ id: "dateFormatType" })}
              minDate={startDate}
            />
          </Box>
        </LocalizationProvider>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <OutlinedInput
            size="large"
            placeholder={intl.formatMessage({ id: "invoiceNum" })}
            value={invoiceNumber}
            onChange={(event) => setInvoiceNumber(event.target.value)}
            sx={{ width: '50%' }}
          />
            <Select
                size="large"
                displayEmpty
                value={countryOfOrigin}
                onChange={(event) => setCountryOfOrigin(event.target.value || null)}
                sx={{ width: '50%' }}
                renderValue={(selected) =>
                    selected ? (
                        selected
                    ) : (
                        <Typography sx={{ color: 'grey.500' }}>
                            {intl.formatMessage({ id: "country" })}
                        </Typography>
                    )
                }
                endAdornment={
                    countryOfOrigin && (
                        <InputAdornment position="end"  sx={{ paddingRight: '8px' }}>
                            <IconButton
                                aria-label="clear selection"
                                onClick={() => setCountryOfOrigin(null)} 
                                edge="end"
                                size="small"
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        </InputAdornment>
                    )
                }
            >
                <MenuItem value="CH">CH</MenuItem>
                <MenuItem value="LI">LI</MenuItem>
            </Select>
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
        <OutlinedInput
          size="large"
          placeholder={intl.formatMessage({ id: "document_number" })}
          value={documentNumber}
          onChange={(event) => setDocumentNumber(event.target.value)}
          sx={{ width: '50%' }}
        />
        <OutlinedInput
          size="large"
          placeholder={intl.formatMessage({ id: "company" })}
          value={company}
          onChange={(event) => {
            const value = event.target.value;
            setCompany(value);
          }}
          sx={{ width: '50%' }}
        />
        </Box>
        <OutlinedInput
          size="large"
          placeholder={intl.formatMessage({ id: "document_type" })}
          value={documentType}
          onChange={(event) => {
            const value = event.target.value;
            if (validateTextInput(value)) {
              setDocumentType(value);
            }
          }}
          sx={{ width: '100%' }}
        />

        <OutlinedInput
          size="large"
          placeholder={intl.formatMessage({ id: "profile" })}
          value={profile}
          onChange={(event) => {
            const value = event.target.value;
            if (validateTextInput(value)) {
              setProfile(value);
            }
          }}
          sx={{ width: '100%' }}
        />

        

        <Box sx={{ width: '100%' }}>
          <Autocomplete
            value={documentCategories.find(type => type.value === documentCategory) || null}
            onChange={handleDocCatChange}
            options={documentCategories}
            getOptionLabel={(option) => intl.formatMessage({ id: option.label })}
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder={intl.formatMessage({ id: "category" })} fullWidth />}
          />
        </Box>
      </Box>
    </Box>
  );
};

const DocumentFields = () => {
  const [fields, setFields] = useState([]);
  const intl = useIntl();
  const { documentCategory, documentFields, setDocumentFields, possibleCurrencies,dynamicField } = useSearch();

  useEffect(() => {
    if (documentCategory) {
      const docTypeKey = documentCategory.toUpperCase();
      const documentSpecificFields = dynamicField?.main[docTypeKey] || [];
      const generalFields = (dynamicField?.main.general || []).slice().reverse();
      const combinedFields = [...new Set([...generalFields, ...documentSpecificFields])];

      setFields(combinedFields);
    } else {
      setFields([]);
    }
  }, [documentCategory]);

  const handleFieldChange = (field, value) => {
    setDocumentFields(prevFields => ({
      ...prevFields,
      [field]: value
    }));
  };

  if (!documentCategory || fields.length === 0) {
    return null;
  }

  return (
    <Box sx={{ mt: 0, display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
      <Box
        sx={{
          maxHeight: 55 * 4,
          overflowY: fields.length > 4 ? 'auto' : 'unset',
          pr: 1,
        }}
      >
        {fields.map((field) => {

          if (field === 'currency') {
            return (
              <Autocomplete
                key={field}
                value={possibleCurrencies.find(currency => currency === documentFields.currency) || null}
                onChange={(event, newValue) => {
                  setDocumentFields(prevFields => ({
                    ...prevFields,
                    currency: newValue || ''
                  }));
                }}
                options={possibleCurrencies}
                renderInput={(params) => (
                  <TextField className='currency_align'
                    sx={{ display: 'flex', gap: 1, width: '100%', mb: 2 }}
                    {...params}
                    variant="outlined"
                    placeholder={intl.formatMessage({ id: "currency" })}
                    fullWidth
                  />
                )}
                isOptionEqualToValue={(option, value) => option === value}
              />
            );
          }
          if (field === 'status') {
            const menuItems = [
              { value: 'completed', label: 'completed' },
              { value: 'canceled', label: 'canceled' },
          ];
            
            return (
              <Box sx={{ display: 'flex', gap: 1, mb: 2 }} key={field}>
                  <Select
                      size="large"
                      displayEmpty
                      value={documentFields.status || ''}
                      onChange={(event) =>
                          setDocumentFields(prevFields => ({
                              ...prevFields,
                              status: event.target.value || null,
                          }))
                      }
                      sx={{ width: '100%' }}
                      renderValue={(selected) =>
                          selected ? (
                            intl.formatMessage({ id: selected })
                          ) : (
                              <Typography sx={{ color: 'grey.500' }}>
                                  {intl.formatMessage({ id: "status" })}
                              </Typography>
                          )
                      }
                  >
                      {menuItems.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                              {intl.formatMessage({ id: item.label })}
                          </MenuItem>
                      ))}
                  </Select>
              </Box>
          );
        }
          
          if (field && field.includes('_date')) {
            return (
              <LocalizationProvider key={field} dateAdapter={AdapterDayjs}>
                <Box sx={{ display: 'flex', gap: 1, width: '100%', mb: 2 }}>
                  <DatePicker
                    label={intl.formatMessage({ id: field })} // Localized label
                    value={documentFields[field] || null} // Use dynamic access for field value
                    onChange={(newValue) => {
                      setDocumentFields((prevFields) => ({
                        ...prevFields,
                        [field]: newValue, // Use computed property to update the correct field                    
                      }));
                    }}
                    inputFormat={intl.formatMessage({ id: 'dateFormatType' })} // Set inputFormat dynamically
                    renderInput={(params) => (
                      <TextField {...params}
                        fullWidth
                        InputLabelProps={{
                          sx: { pb: 1 },
                        }}
                      /> // Ensure full width input
                    )}
                  
                  />
                </Box>
              </LocalizationProvider>
            );
          }
          return (
            <OutlinedInput
              key={field}
              size="large"
              placeholder={intl.formatMessage({ id: field })}
              value={documentFields[field] || ''}
              onChange={(e) => handleFieldChange(field, e.target.value)}
              sx={{ width: '100%', mb: 2 }}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export { BasicDetails, DocumentFields };