import PropTypes from "prop-types";
import { useMemo, Fragment, useRef } from "react";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'; // Default icon
import DescriptionIcon from '@mui/icons-material/Description'; // Example icon for 'reports'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ReceiptIcon from '@mui/icons-material/Receipt';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
    Box,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import {
    useExpanded,
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    useSortBy,
    useTable,
} from "react-table";
import {
    CSVExport,
    TableRowSelection,
} from "components/third-party/ReactTable";
import {
    renderFilterTypes,
    GlobalFilter,
    DateColumnFilter,
} from "utils/react-table";
import { FormattedMessage } from "react-intl";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useSearch } from "hooks/useSearchContext";
import { useNavigate } from "react-router";
// import { useNavigate } from "react-router";
export function SearchTable({
    columns,
    data,
    resultSize,
    customLinePadding,
    tableName,
    showDetailsStack,
    categoryTypes
}) {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));
    const defaultColumn = useMemo(() => ({ Filter: DateColumnFilter }), []);
    const filterTypes = useMemo(() => renderFilterTypes, []);
    const pageSize = 5; // Set page size to 5
    const pageIndex = 0;

    const iconMapping = {
        liefs: <NotificationsActiveIcon sx={{ fontSize: '20px', color: theme.palette.primary.dark }} />,
        abrli: <ReceiptLongIcon sx={{ fontSize: '20px', color: theme.palette.primary.dark }} />,
        einau: <ShoppingBasketIcon sx={{ fontSize: '20px', color: theme.palette.primary.dark }} />,
        einre: <DescriptionIcon sx={{ fontSize: '20px', color: theme.palette.primary.dark }} />,
        sscce: <BookmarksIcon sx={{ fontSize: '20px', color: theme.palette.primary.dark }} />,
        frabr: <LocalShippingIcon sx={{ fontSize: '20px', color: theme.palette.primary.dark }} />,
        samre: <DescriptionIcon sx={{ fontSize: '20px', color: theme.palette.primary.dark }} />,
        waree: <ReceiptIcon sx={{ fontSize: '20px', color: theme.palette.primary.dark }} />,
        invoice: <DescriptionIcon sx={{ fontSize: '20px', color: theme.palette.primary.dark }} />,
        co: <DescriptionIcon sx={{ fontSize: '20px', color: theme.palette.primary.dark }} />,
        standard: <InsertDriveFileIcon sx={{ fontSize: '20px', color: theme.palette.primary.dark }} />,
    };

    const navigate = useNavigate();

    const {  setDocumentCategory } = useSearch();
    const handleCardClick = (categoryType) => {
        const newCategory = categoryType.value
        setDocumentCategory(newCategory);
        if (window.location.pathname !== '/archive/search') {
            navigate('/archive/search');
          }
      };

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        page,
        state: { globalFilter, selectedRowIds },
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            filterTypes,
            defaultColumn,
            manualPagination: true,
            pageCount: Math.ceil(resultSize / pageSize),
            autoResetPage: false,
            initialState: { pageIndex, pageSize }, // Initialize pagination state with pageSize of 5
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination, // Include usePagination to handle pagination
        useRowSelect
    );

    const componentRef = useRef(null);

   return (
        <>
            <Box className='tbl_viw_algn' sx={{ width: "100%" }}></Box>
            <Typography>
                <FormattedMessage id="results" />
            </Typography>

            {showDetailsStack && (
                <Stack
                    direction={matchDownSM ? "column" : "row"}
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Stack direction={matchDownSM ? "column" : "row"} spacing={2}>
                        <GlobalFilter
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                            size="medium"
                        />
                    </Stack>
                    <Stack
                        direction={matchDownSM ? "column" : "row"}
                        alignItems="center"
                        spacing={matchDownSM ? 1 : 2}
                    >
                        <TableRowSelection selected={Object.keys(selectedRowIds).length} />
                        <CSVExport data={data} filename={"invoice-list.csv"} />
                    </Stack>
                </Stack>
            )}

            <Box ref={componentRef}>
                <Table {...getTableProps()}>
                    <TableBody {...getTableBodyProps()}>
                        {/* First render the categoryTypes rows */}
                        {categoryTypes.map((categoryType) => (
                            <TableRow
                                key={categoryType.value}
                                onClick={() => handleCardClick(categoryType)}
                                sx={{ cursor: 'pointer', '&:hover': { backgroundColor: theme.palette.action.hover } }}
                            >
                                <TableCell sx={{ display: "flex", gap: 2, padding: "8px 0px", paddingLeft: 0 }}>
                                    {iconMapping[categoryType.value] || <ReceiptLongIcon sx={{ fontSize: "20px", color: theme.palette.primary.dark }} />}
                                    <Typography variant="h6">
                                        <FormattedMessage id={categoryType.label} />
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}

                        {/* Now render the data rows */}
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <Fragment key={i}>
                                    <TableRow
                                        {...row.getRowProps()}
                                        onClick={() => row.toggleRowSelected()}
                                        sx={{
                                            cursor: "pointer",
                                            bgcolor: row.isSelected
                                                ? alpha(theme.palette.primary.lighter, 0.35)
                                                : "inherit",
                                            padding: 0,
                                        }}
                                    >
                                        {row.cells.map((cell, i) => (
                                            <TableCell
                                                key={i}
                                                {...cell.getCellProps([{ className: cell.column.id }])}
                                                sx={{
                                                    padding: `${customLinePadding}px !important`,
                                                    paddingLeft: '.5em !important',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Box sx={{ flex: 1 }}>
                                                    {cell.render("Cell")}
                                                </Box>

                                                <KeyboardArrowRightIcon />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </Fragment>
                            );
                        })}

                        {/* Show message if no entries found */}
                        {data.length === 0 && tableName === "SearchTable" && (
                            <TableRow sx={{ "&:hover": { bgcolor: "transparent !important" }, padding: 0 }}>
                                <TableCell sx={{ p: 0 }} colSpan={9}>
                                    <FormattedMessage id="no-entries-found" />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Box>
        </>
    );
}

SearchTable.propTypes = {
    columns: PropTypes.array,
    resultSize: PropTypes.number,
    data: PropTypes.array,
    showUploadButton: PropTypes.bool,
    onPageChange: PropTypes.func,
    customLinePadding: PropTypes.number,
    tableName: PropTypes.string,
    showPagination: PropTypes.bool,
};
