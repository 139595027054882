import { useTheme } from '@mui/material/styles';
import { Box, Tooltip } from '@mui/material';
import IconButton from 'components/@extended/IconButton';
import { SunOutlined, MoonOutlined } from '@ant-design/icons';
import useConfig from 'hooks/useConfig';
import ThemeMode from 'config';
import { useIntl } from 'react-intl';

const ThemeSwitch = () => {
  const theme = useTheme();
  const { mode, onChangeMode } = useConfig();

  const iconBackColor = theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'grey.100';

  const handleModeChange = () => {
    const newMode = mode === 'light' ? 'dark' : 'light';

    onChangeMode(newMode);
  };
  const intl = useIntl();

  return (
    <>
      <Box sx={{ flexShrink: 0, ml: 0.75 }}>
        <Tooltip title={intl.formatMessage({ id: `change_theme` })}>

          <IconButton
            color='secondary'
            variant='light'
            sx={{
              color: 'text.primary',
              bgcolor: iconBackColor,
            }}
            onClick={handleModeChange}
            aria-label='Theme toggler'
          >
            {theme.palette.mode === ThemeMode.DARK ? <SunOutlined /> : <MoonOutlined />}
          </IconButton>
        </Tooltip>
      </Box>
    </>
  );
};

export default ThemeSwitch;
