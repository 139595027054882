import { useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, ClickAwayListener, Grid, List, ListItemButton, ListItemText, Paper, Popper, Tooltip, Typography, useMediaQuery } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
// project import
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';
import useConfig from 'hooks/useConfig';
import { FormattedMessage, useIntl } from 'react-intl';
import germanFlag from 'assets/images/flags/de.svg';
import englishFlag from 'assets/images/flags/en.svg';
import frenchFlag from 'assets/images/flags/fr.svg';
import 'assets/third-party/react-table.css';
import { Button } from '@mui/material';
// import DashboardIcon from '@mui/icons-material/Dashboard';
 import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
// import PersonIcon from '@mui/icons-material/Person';
// ==============================|| HEADER CONTENT - LOCALIZATION ||============================== //

const Localization = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const { i18n, onChangeLocalization } = useConfig();

    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (lang) => {
        onChangeLocalization(lang);
        setOpen(false);
    };

    const intl = useIntl();

    const handleRedirect = () => {
        let redirectUrl = localStorage.getItem('redirectLogin');
        let result = redirectUrl.replace(/"/g, '');
        window.location.assign(result);
      };

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
              <Tooltip title={intl.formatMessage({ id: "header_user_portal" })}>
              <Button 
                    className='BtnStyl'
                    color="secondary"
                   
                    variant="contained"  // Use "contained" to enable background color
                    sx={{ 
                        color: 'text.primary',
                        border: '1px solid #adbd2f',
                        borderRadius:'0px',
                        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1e1e1e',  // Set custom background color
                        marginRight: '10px',
                        '&:hover': { backgroundColor: theme.palette.mode === 'light' ?'#e0e0e0':'rgb(103 102 102)' } // Optional: background color on hover
                    }}
                    onClick={handleRedirect}
                    startIcon={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                            
                             {/* Reduced gap to bring icons closer */}
                          <NavigateBeforeIcon /> <FormattedMessage id="header_user_portal" />
                          {/* <PersonIcon /> */}
                        </Box>
                      }
                    >
                </Button>
                </Tooltip>
            <Tooltip title={intl.formatMessage({ id: "change_language" })}>
                <IconButton
                    color="secondary"
                    variant="light"
                    sx={{ color: 'text.primary' }}
                    aria-label="open localization"
                    ref={anchorRef}
                    aria-controls={open ? 'localization-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    <LanguageIcon />
                </IconButton>
            </Tooltip>
            <Popper
                placement={matchesXs ? 'bottom-start' : 'bottom'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 0 : 0, 9]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="grow" position={matchesXs ? 'top-right' : 'top'} in={open} {...TransitionProps}>
                        <Paper sx={{ boxShadow: theme.customShadows.z1 }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <List
                                    component="nav"
                                    sx={{
                                        p: 0,
                                        width: '100%',
                                        minWidth: 200,
                                        maxWidth: 290,
                                        bgcolor: theme.palette.background.paper,
                                        borderRadius: 0.5,
                                        [theme.breakpoints.down('md')]: {
                                            maxWidth: 250
                                        }
                                    }}
                                >
                                <ListItemButton selected={i18n === 'de'} onClick={() => handleListItemClick('de')} sx={{height:'40px' }}>
                                    <ListItemText className='list_styl' 
                                        primary={
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item>
                                            <img  className='flag-style' src={germanFlag}  width="18" height="15" alt="" />
                                            </Grid>
                                            <Grid item>
                                            <Typography color="textPrimary">
                                                <FormattedMessage id="german" />
                                            </Typography>
                                            </Grid>
                                        </Grid>
                                        }
                                    />
                                </ListItemButton>

                                <ListItemButton selected={i18n === 'en'} onClick={() => handleListItemClick('en')} sx={{height:'40px' }}>
                                    <ListItemText 
                                        primary={
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item>
                                            <img className='flag-style'  src={englishFlag} width="18" height="15" alt="" />
                                            </Grid>
                                            <Grid item>
                                            <Typography color="textPrimary">
                                                <FormattedMessage id="english" />
                                            </Typography>
                                            </Grid>
                                        </Grid>
                                        }
                                    />
                                </ListItemButton>

                                <ListItemButton selected={i18n === 'fr'} onClick={() => handleListItemClick('fr')} sx={{height:'40px' }}>
                                    <ListItemText 
                                        primary={
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item>
                                            <img className='flag-style'  src={frenchFlag} width="18" height="15" alt="" />
                                            </Grid>
                                            <Grid item>
                                            <Typography color="textPrimary">
                                                <FormattedMessage id="french" />
                                            </Typography>
                                            </Grid>
                                        </Grid>
                                        }
                                    />
                                </ListItemButton>


                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
};

export default Localization;