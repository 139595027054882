import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import useHandleCache from 'hooks/useHandleCache'; // Custom hook for handling cache version
import React, { useEffect, useState } from 'react';
import { Modal, Button, Card } from '@mui/material';

const App = () => {
  const { isLoading, isLatestVersionAvailable } = useHandleCache(); // Hook to check version mismatch
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  useEffect(() => {
    // Show the update modal if a new version is detected
    if (!isLatestVersionAvailable) {
      setShowUpdateModal(true);
    }
  }, [isLatestVersionAvailable]);

  useEffect(() => {
    // Listen for service worker messages
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data && event.data.type === 'NEW_VERSION') {
          setShowUpdateModal(true);
        }
      });
    }
  }, []);

  const handleRefresh = () => {
    setShowUpdateModal(false);
    window.location.reload(true); // Force reload to get the new version
  };

  if (isLoading) {
    return <div>Loading...</div>; // Show a loading spinner or placeholder while checking the version
  }

  return (
    <ThemeCustomization>
      <Locales>
        <ScrollTop>
          <AuthProvider>
            <>
              <Notistack>
                <Routes />
                <Snackbar />
              </Notistack>
            </>
          </AuthProvider>
        </ScrollTop>
      </Locales>

      {/* Update Modal */}
      {showUpdateModal && (
        <Modal open={true} onClose={() => setShowUpdateModal(false)}>
          <Card
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              padding: '20px',
              borderRadius: '8px',
              boxShadow: 24,
              outline: 'none',
              textAlign: 'center',
            }}
          >
            <h2>Update Available</h2>
            <p>A new version of the app is available. Please refresh to update.</p>
            <Button variant="contained" color="primary" onClick={handleRefresh}>
              Refresh
            </Button>
          </Card>
        </Modal>
      )}
    </ThemeCustomization>
  );
};

export default App;
