import { useEffect, useState } from 'react';
import packageJson from '../../package.json';

const checkVersionMismatch = (latestVersion, currentVersion) => {
  const latest = latestVersion.split('.');
  const current = currentVersion.split('.');

  while (latest.length || current.length) {
    const a = Number(latest.shift());
    const b = Number(current.shift());

    if (a === b) continue;
    return a > b || isNaN(b);
  }

  return false;
};

const useHandleCache = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLatestVersionAvailable, setIsLatestVersionAvailable] = useState(true);

  useEffect(() => {
    const fetchMetaVersion = async () => {
      try {
        const response = await fetch('/meta.json', { cache: 'no-store' });
        const meta = await response.json();
        const latestVersion = meta.version;
        const currentVersion = packageJson.version;

        const shouldForceRefresh = checkVersionMismatch(latestVersion, currentVersion);

        if (shouldForceRefresh) {
          console.log(`New version ${latestVersion} available. Prompting user to reload.`);
          setIsLatestVersionAvailable(false);
        } else {
          console.log(`Already on the latest version ${latestVersion}.`);
          setIsLatestVersionAvailable(true);
        }
      } catch (error) {
        console.error('Error fetching meta.json:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMetaVersion();
  }, []);

  return { isLoading, isLatestVersionAvailable };
};

export default useHandleCache;
