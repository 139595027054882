import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const Footer = () => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: '24px 16px 0px', mt: 'auto' }}>
    <Typography variant="caption">&copy; All rights reserved</Typography>
    <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center">
      {/* <Link component={RouterLink} to="#" variant="caption" color="textPrimary">
        <FormattedMessage id='about_us'>

        </FormattedMessage>
      </Link> */}
      <Link component={RouterLink} to="/privacy-policy" variant="caption" color="textPrimary">
        <FormattedMessage id='privacy'>

        </FormattedMessage>
      </Link>
      <Link component={RouterLink} to="/terms-condition" variant="caption" color="textPrimary">
        <FormattedMessage id='terms'>

        </FormattedMessage>
      </Link>
      <Link component={RouterLink} to="/legal-notices" variant="caption" color="textPrimary">
        <FormattedMessage id='legal_notices'>

        </FormattedMessage>
      </Link>
    </Stack>
  </Stack>
);

export default Footer;
